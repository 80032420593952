$mdc-theme-primary: #12bc96;
$mdc-theme-primary-light: #d2fbf2;
$mdc-theme-secondary: #2a2a2a;
$mdc-theme-success: #28a745;
$mdc-theme-error: #dc3545;
$mdc-theme-warning: #ffc107;
$mdc-theme-info: #17a2b8;
$mdc-theme-white: #ffffff;
$mdc-theme-gray: #f5f5f5;
$mdc-theme-gray-100: #e8e8e8;
$mdc-theme-on-primary: #fff;

$mdc-typography-font-family: "Open Sans", sans-serif;

:root {
  --mdc-theme-primary: #{$mdc-theme-primary};
  --mdc-theme-primary-light: #{$mdc-theme-primary-light};
  --mdc-theme-secondary: #{$mdc-theme-secondary};
  --mdc-theme-success: #{$mdc-theme-success};
  --mdc-theme-error: #{$mdc-theme-error};
  --nodata-clip-color: #{$mdc-theme-primary};
  --logo-color: #fcfcfc;
  --logo-dark-color: #{$mdc-theme-secondary};
  --mdc-theme-warning: #{$mdc-theme-warning};
  --mdc-theme-info: #{$mdc-theme-info};
  --mdc-theme-white: #{$mdc-theme-white};
  --mdc-theme-gray: #{$mdc-theme-gray};
  --mdc-theme-gray-100: #{$mdc-theme-gray-100};
  --mdc-theme-on-primary: #{$mdc-theme-on-primary};
}

@import "core/assets/core.scss";

.mdc-list-item--selected,
.mdc-list-item--activated {
  color: darken($mdc-theme-primary, 30%);
}

.mdc-tab-indicator > .mdc-tab-indicator__content--underline {
  height: 3px;
}

body {
  margin: 0;
  font-family: $mdc-typography-font-family;
}
