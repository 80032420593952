@import "~@fontsource/open-sans/scss/mixins";

@include fontFace(
  $weight: 400,
  $display: auto,
  $style: "normal"
  // unicodeMap = by default all ranges are included latin, cyrillic, greek, + all -ext, hebrew vietnamese
);


// lighter
@include fontFace(
  $weight: 300,
  $display: auto,
);


// bold
@include fontFace(
  $weight: 500,
  $display: auto,
);

// Not needed yet
// 300-italic
// 500-italic

